const messages = {
  pt: {
    translations: {
      header: {
				greetings: {
					hello: "Olá",
					welcome: "Bem-vido à",
          activeUntil: "Ativo até"
				},
			},

      dashboard: {
        title: {
          inservice: "EM ATENDIMENTO",
          waiting: "AGUARDANDO ATENDIMENTO",
          onlineAgents: "ATENDENTES ONLINE",
          completedTickets: "ATENDIMENTOS FINALIZADOS",
          totalSentMessages: "TOTAL DE MSG ENVIADAS",
          totalReceivedMessages: "TOTAL DE MSG RECEBIDAS",
          newLeads: "NOVOS LEADS",
          averageHandlingTime: "TEMPO MÉDIO DE ATENDIMENTO",
          averageWaitTime: "TEMPO MÉDIO DE ESPERA",
          totalUserInteractions: "Total de Atendimento por Usuário",
          totalInteractions: "Atendimento Total",
          startDate: "Data Inicial",
          endDate: "Data Final",
          filter: "FILTRAR",
          showFilterDate: "VER ATENDIMENTOS POR PERÍODO",
					showFilterUser: "VER ATENDIMENTOS POR USUÁRIO",
					hideFilter: "OCULTAR FILTRO"
        }
      },

      messageVariablesPicker: {
        label: "Variavéis disponíveis",
        vars: {
          contactFirstName: "Primeiro Nome",
          contactName: "Nome",
          user: "Atendente",
          greeting: "Saudação",
          protocolNumber: "Protocolo",
          date: "Data",
          hour: "Hora",
          ticket_id: "Nº do Chamado",
          queue: "Setor",
          connection: "Conexão"
        }
      },
      quickemessage: {
        toasts: {
          success: "Atalho adicionado com sucesso!",
          deleted: "Atalho removido com sucesso!",
        },
        title: "Respostas Rápidas",
        buttons: {
          add: "Nova Resposta",
        },
        dialog: {
          add: "Adicionar",
          edit: "Editar",
          form: {
            shortcode: "Atalho",
            message: "Mensagem",
            geral: "Global",
          },
          buttons: {
            cancel: "Cancelar",
            edit: "Salvar",
            attach: "Anexar",
            add: "Salvar",
          },
        },
        confirmationModal: {
          deleteTitle: "Exclusão",
          deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",  
        },
      },
      quickemessages: {
        toasts: {
          success: "Atalho adicionado com sucesso!",
          deleted: "Atalho removido com sucesso!",
        },
        title: "Respostas Rápidas",
        table: {
          shortcode: "Atalho",
          mediaName: "Arquivo",
          status: "Global",
          actions: "Ação",
        },
        searchPlaceholder: "Procurar",
        buttons: {
          add: "Adicionar",
          attach: "Anexar Arquivo",
          cancel: "Cancelar",
          edit: "Salvar",
        },
        confirmationModal: {
          deleteTitle: "Exclusão",
          deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",  
        },
      },
      signup: {
        title: "CADASTRE-SE",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          company: "Nome da Empresa",
          phone: "Whatsapp (DDD + NÚMERO)"
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      forgotpassword: {
        toasts: {
          success: "Email enviado com sucesso!",
          error: "Email não encontrado!",
        },
        passwordRules: "Sua senha precisa ter no mínimo 8 caracteres, sendo uma letra maiúscula, uma minúscula e um número.",
      },
      smtpCredentials: {
        toasts: {
          error: "Ocorreu um erro. Credenciais de SMTP.",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
        dueDate: {
          expiration: "Sua assinatura expira em",
          days: "dias!",
          day: "dia!",
          expirationToday: "Sua assinatura expira hoje!",
        },
        token: "Token",
      },
      companies: {
        title: "Adicionar Empresa",
        form: {
          name: "Nome da Empresa",
          plan: "Plano",
          token: "Token",
          submit: "Cadastrar",
          success: "Empresa criada com sucesso!",
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content: "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content: "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content: "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
          
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
          number: "Número do Whatsapp"
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          maxUseBotQueues: "Quantidade máxima de vezes que o chatbot vai ser enviado",
          expiresTicket: "Encerrar chats abertos após x horas",
          outOfHoursMessage: "Mensagem de fora de expediente",
          greetingMessage: "Mensagem de saudação",
          complationMessage: "Mensagem de conclusão",
          token: "Token para integração externa",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
          export: "Exportar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      forwardMessage: {
        text: "Encaminhada"
      },
      forwardMessageModal: {
        title: "Encaminhar mensagem",
        buttons: {
          ok: "Encaminhar"
        }
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          complationMessage: "Mensagem de conclusão",
          outOfHoursMessage: "Mensagem de fora de expediente",
          token: "Token",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          farewellMessage: "Mensagem de despedida",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          startWork: "Inicio de trabalho",
          endWork: "Fim de trabalho"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      companyModal: {
        title: {
          add: "Adicionar empresa",
          edit: "Editar empresa",
        },
        form: {
          name: "Nome",
          email: "Email",
          passwordDefault: "Senha",
          numberAttendants: "Usuários",
          numberConections: "Conexões",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Empresa salva com sucesso.",
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      ratingModal: {
        title: {
          add: "Adicionar menu de avaliação",
          edit: "Editar menu de avaliação",
        },
        buttons: {
          okAdd: "Salvar",
          okEdit: "Editar",
          cancel: "Cancelar",
          options: "Adicionar opção",
        },
        form: {
          name: "Nome",
          message: "Mensagem da Avaliação",
          options: "Opções de Avaliação",
          extraName: "Nome da opção",
          extraValue: "Valor da opção",
        },
        success: "Avaliação salva com sucesso!",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O atendimento que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Abertas" },
          closed: { title: "Resolvidos" },
          group: { title: "Grupos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar atendimento e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Atendimento",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldQueuePlaceholder: "Selecione uma fila",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum atendimento encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
          reopen: "Reabrir",
          closed: "Fechar"
        },
      },
      newTicketModal: {
        title: "Criar Atendimento",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        fieldQueuePlaceholder: "Selecione uma fila",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimentos",
          quickMessages: "Respostas Rápidas",
          contacts: "Contatos",
          queues: "Filas & Chatbot",
          tags: "Tags",
          administration: "Administração",
          companies: "Empresas",
          users: "Usuários",
          settings: "Configurações",
          ratings: "Avaliação",
          helps: "Ajuda",
          messagesAPI: "API",
          schedules: "Agendamentos",
          campaigns: "Campanhas",
          annoucements: "Informativos",
          chats: "Chat Interno",
          financeiro: "Financeiro",
          logout: "Sair",
          version: "Versão",
          toDoList: "Tarefas",
          panel: "Painel",
					lanes: "Quadros",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
          refresh: "Atualizar"
        },
      },
      languages: {
        undefined: "Idioma",
        "pt-BR": "Português",
        es: "Español",
        en: "English",
        tr: "Türkçe"
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Número",
          body: "Mensagem",
          token: "Token cadastrado",
        },
        mediaMessage: {
          number: "Número",
          body: "Nome do arquivo",
          media: "Arquivo",
          token: "Token cadastrado",
          caption: "Legenda",
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      quickMessages: {
        title: "Respostas Rápidas",
        buttons: {
          add: "Nova Resposta",
        },
        dialog: {
          shortcode: "Atalho",
          message: "Resposta",
        },
      },
      contactLists: {
        title: "Listas de Contatos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Lista",
        },
        dialog: {
          name: "Nome",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      contactListItems: {
        title: "Contatos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo",
          lists: "Listas",
          import: "Importar",
        },
        dialog: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
          importMessage: "Deseja importar os contatos desta planilha? ",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      campaigns: {
        title: "Campanhas",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Nova Campanha",
          contactLists: "Listas de Contatos",
        },
        table: {
          name: "Nome",
          whatsapp: "Conexão",
          contactList: "Lista de Contatos",
          status: "Status",
          scheduledAt: "Agendamento",
          completedAt: "Concluída",
          confirmation: "Confirmação",
          actions: "Ações",
        },
        dialog: {
          new: "Nova Campanha",
          update: "Editar Campanha",
          readonly: "Apenas Visualização",
          form: {
            name: "Nome",
            message1: "Mensagem 1",
            message2: "Mensagem 2",
            message3: "Mensagem 3",
            message4: "Mensagem 4",
            message5: "Mensagem 5",
            confirmationMessage1: "Mensagem de Confirmação 1",
            confirmationMessage2: "Mensagem de Confirmação 2",
            confirmationMessage3: "Mensagem de Confirmação 3",
            confirmationMessage4: "Mensagem de Confirmação 4",
            confirmationMessage5: "Mensagem de Confirmação 5",
            messagePlaceholder: "Conteúdo da mensagem",
            whatsapp: "Conexão",
            status: "Status",
            scheduledAt: "Agendamento",
            confirmation: "Confirmação",
            contactList: "Lista de Contato",
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar Disparos",
            restart: "Reiniciar Disparos",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          cancel: "Campanha cancelada",
          restart: "Campanha reiniciada",
          deleted: "Registro excluído",
        },
      },
      announcements: {
        title: "Informativos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo Informativo",
          contactLists: "Listas de Informativos",
        },
        table: {
          priority: "Prioridade",
          title: "Title",
          text: "Texto",
          mediaName: "Arquivo",
          status: "Status",
          actions: "Ações",
        },
        dialog: {
          edit: "Edição de Informativo",
          add: "Novo Informativo",
          update: "Editar Informativo",
          readonly: "Apenas Visualização",
          form: {
            priority: "Prioridade",
            title: "Title",
            text: "Texto",
            mediaPath: "Arquivo",
            status: "Status",
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          deleted: "Registro excluído",
        },
      },
      campaignsConfig: {
        title: "Configurações de Campanhas",
      },
      queues: {
        title: "Filas & Chatbot",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      users: {
        title: "Usuários",
        table: {
          id: "ID",
          status: "Status",
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrao",
          startWork: "Inicio de trabalho",
          endWork: "Fim de trabalho",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para a fila.",
        },
      },
      compaies: {
        title: "Empresas",
        table: {
          status: "Ativo",
          name: "Nome",
          email: "Email",
          numberAttendants: "Atendentes",
          numberConections: "Conexões",
          value: "Valor",
          namePlan: "Nome Plano",
          numberQueues: "Filas",
          useCampaigns: "Campanhas",
          useExternalApi: "Rest API",
          useFacebook: "Facebook",
          useInstagram: "Instagram",
          useWhatsapp: "Whatsapp",
          useInternalChat: "Chat Interno",
          useSchedules: "Agendamento",
          createdAt: "Criada Em",
          dueDate: "Vencimento",
          lastLogin: "Ult. Login",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar empresa",
        },
        toasts: {
          deleted: "Empresa excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados da empresa serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      helps: {
        title: "Central de Ajuda",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        },
      },
      ratings: {
        title: "Avaliações",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ação"
        },
        toasts: {
          deleted: "Avaliação excluída com sucesso!",
          deletedAll: "Todas as avaliações foram excluídas com sucesso!",
        },
        buttons: {
          add: "Adicionar",
          deleteAll: "Deletar Todos",
        },
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteAllTitle: "Deletar Todos",
          deleteMessage: "Tem certeza que deseja deletar esta avaliação?",
          deleteAllMessage: "Tem certeza que deseja deletar todas as avaliações?",
        },
      },
      settings: {
        options: {
					title: "Opções",
					enabled: "Ativado",
					disabled: "Desativado",
					officeScheduling: "Agendamento de expediente",
					queueSectorManagement: "Gerenciamento de Filas/Setores",
					managementByCompany: "Gerenciamento por empresa",
					sendGreetingAcceptingService: "Enviar saudação ao aceitar o atendimento",
					chooseRandomOperatorWhenChoosingSector: "Escolher Operador Aleatório Ao Escolher Setor",
					sendSectorAttendantTransferMessage: "Enviar mensagem de transferência de Setor/Atendente",
          ignoreGroupMessages: "Ignorar mensagens de grupo",
          botType: "Tipo de Bot",
          notAcceptCallsOnWhatsApp: "Informar que não aceita ligação no whatsapp",
          allowsAttendantChooseSendSignature: "Permite atendente escolher ENVIAR Assinatura",
          sendGreetingWhenOneSector: "Enviar saudação quando houver somente 1 setor",
          sendMessageWithQueuePosition: "Enviar mensagem com a posicão na fila",
          sendFarewellMessageWhenWaiting: "Enviar mensagem de despedida quando aguardando",
          agreeReceiveAudioFromAllContacts: "Aceita receber audio de todos contatos",
          wordRestartFlow: "Palavra Para Reiniciar Fluxo",
				},
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado à:",
          dialogRatingTitle: "Deseja deixar uma avaliação de atendimento para o cliente?",
          dialogClosingTitle: "Finalizando o atendimento com o cliente!",
          dialogRatingCancel: "Resolver COM Mensagem de Despedida",
          dialogRatingSuccess: "Resolver e Enviar Avaliação",
          dialogRatingWithoutFarewellMsg: "Resolver SEM Mensagem de Despedida",
          ratingTitle: "Escolha um menu de avaliação",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
            rating: "Enviar Avaliação",
          },
        },
        deleted: "Esta mensagem foi apagada pelo Contato.",
        metaPolice: "Você tem 24 horas para responder após receber uma mensagem, seguindo as políticas do Meta.",
        missedCall: "Chamada de voz/vídeo perdida às",
        greetingsLead: "Saudações ao novo Lead!",
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        schedule: "Agendamento",
        delete: "Deletar",
        transfer: "Transferir",
        registerAppointment: "Observações do Contato",
        resolveWithNoFarewell: "Finalizar sem despedida",
        acceptAudioMessage: "Aceitar áudios do contato?",
        appointmentsModal: {
          title: "Observações do Contato",
          textarea: "Observação",
          placeholder: "Insira aqui a informação que deseja registrar",
        },
        confirmationModal: {
          title: "Deletar o atendimento do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao atendimento serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        forward: "Encaminhar",
        toForward: "Encaminhar",
        talkTo: "Conversar Com",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um atendimento aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_OUT_OF_HOURS: "Fora do Horário de Expediente!",
      },
      toDoList: {
				newTask: "Nova Tarefa",
				description: "Descrição"
			},
      commom: {
        title: "Título",
        text: "Texto",
				add: "Adicionar",
        edit: "Editar",
        delete: "Excluir",
				save: "Salvar",
        close: "Fechar",
        new: "Novo",
        cancel: "Cancelar",
        done: "Concluído",
        confirm: "Confirmar",
        name: "Nome",
				action: "Ação",
				actions: "Ações",
        search: "Busca",
        opened: "Em aberto",
        no: "Não",
				yes: "Sim",
        user: "Usuário",
			  users: "Usuários",
        connection: "Conexão",
			  connections: "Conexões",
        queue: "Fila",
			  queues: "Filas",
        updating: "Atualizando",
        view: "Visualizar",
        requiredField: "Campo obrigatório",
			},

      "Login": "Entrar",
      "Name": "Nome",
      "Email": "Email",
      "Password": "Senha",
      "Phone": "Telefone",
      "Enter": "Entrar",
      "Send": "Enviar",
      "Recover Password?": "Redefinir Senha?",
      "Recover Password": "Redefinir Senha",
      "Required field": "Campo obrigatório",
      "Don't have an account? Register!": "Não tem uma conta? Cadastre-se!",
      "Verification Code": "Código de Verificação",
      "New Password": "Nova Senha",
			"Confirm the Password": "Confirme a Senha",
      "Support": "Suporte",
      "Password reset successfully": "Senha redefinida com sucesso",
      "Invalid email": "Email inválido",
      "Passwords do not match": "As senhas não correspondem",
      "Assigned to": "Atribuído à ",
      "Spy Conversation": "Espiar Conversa",
      "No queue": "Sem fila",
      "closed": "fechado",
      "date_format": "dd/MM/yyyy",
      "date_format_moment": "DD/MM/YYYY",
      "hour_format": "HH:mm",
      "No registry": "Sem registro",
      "Your browser does not support the video element": "Seu navegador não suporta o elemento vídeo",
      "This company does not have permission to access this page! We are redirecting you": "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando",
      "Are you sure? All built-in options will also be deleted":"Você tem certeza? Todas as opções internas também serão excluídas",
      "Plan": "Plano",
      "Plans": "Planos",
      "Active": "Ativa",
      "Yes": "Sim",
      "No": "Não",
      "Document": "CPF/CNPJ",
      "Price": "Valor",
      "Due Date": "Data de Vencimento",
      "Last Login": "Último Login",
      "Created at": "Criada em",
      "Recurrence": "Recorrência",
      "Monthly": "Mensal",
      "Bimonthly": "Bimensal",
      "Quarterly": "Trimestral",
      "Semiannual": "Semestral",
      "Annual": "Anual",
      "Clear": "Limpar",
      "Delete": "Excluir",
      "Date": "Data",
      "User": "Usuário",
      "Users": "Usuários",
      "Save": "Salvar",
      "Increment Due Date": "Data Vencimento",
      "Updated successfully": "Atualizado com sucesso",
      "Saved successfully": "Salvo com sucesso",
      "Deleted Successfully": "Excluído com sucesso",
      "Unable to complete this action": "Não foi possível concluir esta ação",
      "Do you really want to delete?": "Deseja realmente excluir?",
      "Unable to load list": "Não foi possível carregar a lista",
      "Check if a company with the same name already exists or if the fields have been filled in correctly": "Verifique se já existe uma empresa com o mesmo nome ou se os campos foram preenchidos corretamente",
      "Connection": "Conexão",
      "Connections": "Conexões",
      "Queue": "Fila",
      "Queues": "Filas",
      "Enabled": "Habilitado",
      "Disabled": "Desabilitado",
      "Campaign": "Campanha",
      "Campaigns": "Campanhas",
      "Schedule": "Agendamento",
      "Schedules": "Agendamentos",
      "Internal Chat": "Chat Interno",
      "External API": "API Externa",
      "Company": "Empresa",
      "Companies": "Empresas",
      "Help": "Ajuda",
      "Timetables updated successfully": "Horários atualizados com sucesso",
      "Add Company/User": "Adicionar Empresa/Usuário",
      "Video Code": "Código do Vídeo",
      "Title": "Título",
      "Description": "Descrição",
      "Listing": "Listagem",
      "Settings": "Configurações",
      "Contact Lists": "Listas de Contatos",
      "day": "dia",
	    "days": "dias",
      "Day of the Week": "Dia da Semana",
      "Start Time": "Hora Inicial",
      "End Time": "Hora Final",
      "Monday": "Segunda-feira",
      "Tuesday": "Terça-feira",
      "Wednesday": "Quarta-feira",
      "Thursday": "Quinta-feira",
      "Friday": "Sexta-feira",
      "Saturday": "Sábado",
      "Sunday": "Domingo",
      "Message": "Mensagem",
      "The message is mandatory to move to the next level": "A mensagem é obrigatória para passar para o próximo nível",
      "If the message is not defined, the bot will not follow you": "Se a mensagem não estiver definida, o bot não o seguirá",
      "File": "Arquivo",
      "Service Hours": "Horários de Atendimento",
      "Queue Data": "Dados da Fila",
      "Click save to record the changes": "Clique em salvar para registar as alterações",
      "Option not saved, save option before adding a file": "Opção não salva, salve a opção antes de adicionar um arquivo",
      "File added": "Arquivo adicionado",
      "Queue saved successfully!": "Fila salva com sucesso!",
      "Too Short!": "Muito curto!",
      "Too Long!": "Muito longo!",
      "Add options": "Adicionar opções",
      "Documentation for sending messages": "Documentação para envio de mensagens",
      "Sending Methods": "Métodos de Envio",
      "Text Messages": "Mensagens de Texto",
      "Media Messages": "Mensagens de Mídia",
      "Instructions": "Instruções",
      "Important Notes": "Observações importantes",
      "Before sending messages, it is necessary to register the token linked to the connection that will send the messages": "Antes de enviar mensagens, é necessário o cadastro do token vinculado à conexão que enviará as mensagens",
      "To register, access the Connections menu, click on the connection's edit button and insert the token in the appropriate field": "Para realizar o cadastro acesse o menu Conexões, clique no botão editar da conexão e insira o token no devido campo",
      "The sending number must not have a mask or special characters and must be composed of": "O número para envio não deve ter mascara ou caracteres especiais e deve ser composto por",
      "Country Code": "Código do país",
      "Number": "Número",
      "Below is the list of information needed to send text messages": "Segue abaixo a lista de informações necessárias para envio das mensagens de texto",
      "Below is the list of information needed to send media messages": "Segue abaixo a lista de informações necessárias para envio das mensagens de mídia",
      "Sending Test": "Teste de Envio",
      "Method": "Método",
      "Registered token": "Token cadastrado",
      "text": "texo",
      "subtitle": "legenda",
      "Body": "Mensagem",
      "Caption": "Legenda",
      "Invalid Email!": "Email inválido!",
      "Updating": "Atualizando",
      "Shows all Messages for the User Profile": "Mostra todas as menssagens para o perfil user",
      "Disable chatbot for this contact": "Desabilitar chatbot para esse contato",
      "Import / Export": "Importar / Exportar",
      "Import from WhatsApp": "Importar do WhatsApp",
      "Import from Excel": "Importar do Excel",
      "Logo updated successfully!": "Logo atualizada com sucesso!",
      "Background image updated successfully!": "Imagem de fundo atualizada com sucesso!",
      "Please upload a JPG, JPEG or PNG file": "Faça upload de um arquivo JPG, JPEG ou PNG",
      "Please upload a file less than 1 MB": "Faça upload de um arquivo com menos de 1 MB",
      "Integrations": "Integrações",
      "This integration is disabled": "Esta integração está desativada",
      "Successfully enabled": "Ativado com sucesso",
      "Successfully disabled": "Desativado com sucesso",
      "The recommended image size is": "O tamanho de imagem recomendado é",
      "Shows Signup Link on Login/Reset Password Page": "Mostra link de inscreva-se",
      "Background Image on Login Page": "Imagem de fundo na página de login",
      "Your subscription expires in": "Sua assinatura vence em",
      "Oops! Your subscription expired on": "Ops! Sua assinatura expirou em",
	    "Contact Support for more information!": "Entre em contato com o Suporte para mais informações!",
      "": "",

      internalChat: "Chat Interno",
      service: "Atendimento",
      services: "Atendimentos",
      durationServices: "Duração dos Serviços",
			groupService: "Atendimento em Grupo",
      resetConnection: "Reiniciar Conexões",
      callSupport: "Chamar no Suporte",
      newConnection: "Nova Conexão",
      filterByUsers: "Filtrar por Usuários",
      companyNoPermissionAccessPage: "Esta empresa não possui permissão para acessar essa página! Estamos redirecionando.",
      deleteConversation: "Excluir Conversa",
      confirmAction: "Esta ação não pode ser revertida. Deseja confirmar?",
      newLane: "Novo Quadro",
      invoice: "Fatura",
			invoices: "Faturas",
			detail: "Detalhe",
			details: "Detalhes",
      price: "Preço",
			prices: "Preço",
      dueDate: "Data de Venc.",
    },
  },
};

export { messages };
